import React, { useMemo, useState } from "react";

import Content from "layout/content/Content";
import Head from "layout/head/Head";

import MessageButton from "components/button/MessageButton";

import Col from "components/custom/Col";
import Row from "components/custom/Row";

import DriverDetailsPageAddressCard from "components/driver-components/DriverDetailsPageAddressCard";
import DriverDetailsPageChargingCard from "components/driver-components/DriverDetailsPageChargingCard";
import DriverDetailsPagePersonalInformationCard
  from "components/driver-components/DriverDetailsPagePersonalInformationCard";

import DriverDetailPageStatusCard from "components/driver-components/DriverDetailsPageStatusCard";
import DriverDetailsPageVehicleSetupCard from "components/driver-components/DriverDetailsPageVehicleSetupCard";
import DriverDetailsChargingTable from "components/driver-components/driver-details/DriverDetailsChargingTable";
import DriverDetailsPageTab from "components/driver-components/driver-details/DriverDetailsPageTab";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackBody2Text100, BlackBody2Text200, BlackH5Text100 } from "components/text/Text";
import { useDriverAsync } from "hooks/data/driver-data-accessor.hooks";
import { DriverDetailsPageTabKey } from "types/driver.types";
import { getLocalFormattedDateAsDateMed } from "utils/date.utils";
import { getDriverFullName, getDriverNameInitials } from "utils/driver.utils";
import DriverDetailsPageReimbursementSettings
  from "../../components/driver-components/DriverDetailsPageReimbursementSettings";
import NotFoundContent from "../../components/not-found/NotFoundContent";

const DriverDetailsPage = () => {
  const driverHook = useDriverAsync();

  const [activeTab, setActiveTab] = useState(DriverDetailsPageTabKey.ABOUT);

  const { isLoading, driver } = driverHook;


  const activeTabContent = useMemo(() => {
    switch (activeTab) {
      case DriverDetailsPageTabKey.ABOUT:
        return <Row>
          <Col lg="6" className="pr-lg-2">
            <DriverDetailPageStatusCard {...driverHook} />

            <div className="mt-4">
              <DriverDetailsPagePersonalInformationCard {...driverHook} />
            </div>
            <div className="mt-4">
              <DriverDetailsPageAddressCard {...driverHook} />
            </div>
          </Col>
          <Col lg="6" className="pl-lg-2 mt-4 mt-lg-0">
            <DriverDetailsPageVehicleSetupCard {...driverHook} />
            <div className="mt-4">
              <DriverDetailsPageChargingCard {...driverHook} />
            </div>
          </Col>
        </Row>;
      case DriverDetailsPageTabKey.CHARGING_SESSIONS:
        return <DriverDetailsChargingTable driver={driver} />;

      case DriverDetailsPageTabKey.REIMBURSEMENT:
        return <DriverDetailsPageReimbursementSettings driverHook={driverHook} />;
    }
  }, [activeTab, driver, driverHook]);


  if (!isLoading && !driver) {
    return <>
      <Head title={"Driver Details"}></Head>
      <NotFoundContent />
    </>;
  }

  return (
    <React.Fragment>
      <Head title={"Driver Details"} />
      <Content>
        <Row className="flex-wrap align-center justify-between mt-2 mb-2">
          {isLoading ? (
            <div className="w-100">
              <LoadingSkeleton count={2} />
            </div>
          ) : (
            <Row className="center mb-2 mb-md-0">
              <div className="driver-details-avatar-badge">{getDriverNameInitials(driver)}</div>
              <Col className="ml-2">
                <BlackH5Text100>{getDriverFullName(driver)}</BlackH5Text100>
                <Row className="align-items-center mt-1">
                  <BlackBody2Text200 className="mr-1">Added:</BlackBody2Text200>
                  <BlackBody2Text100>{getLocalFormattedDateAsDateMed(driver?.createdAt)}</BlackBody2Text100>
                </Row>
              </Col>
            </Row>
          )}

          {!isLoading && <MessageButton text="Send Message" userId={driver?.id} />}
        </Row>
        <div className="mb-4">
          <DriverDetailsPageTab onTabChange={setActiveTab} activeTab={activeTab} />
        </div>
        {activeTabContent}
      </Content>
    </React.Fragment>
  );
};

export default DriverDetailsPage;
