import React, { useState } from "react";
import Content from "layout/content/Content";
import { UserAvatar } from "components/Component";

import { useAuthStatus } from "hooks/auth.hooks";
import { SettingsPageTabs } from "types/settings.types";
import SettingsPageTabLink from "components/settings/SettingsPageTabLink";
import InvoicesContent from "pages/invoice/InvoicesPage";
import Toggle from "layout/sidebar/Toggle";
import Row from "components/custom/Row";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router";
import InvoiceDetailsContent from "pages/invoice/InvoiceDetailsPage";
import Head from "layout/head/Head";
import Col from "components/custom/Col";
import Card from "components/card/Card";
import SettingsPageAccountContent from "components/settings/SettingsPageAccountContent";
import SettingsPageSecurityContent from "components/settings/SettingsPageSecurityContent";
import { BlackBodyText100, BlackOverlineText200 } from "components/text/Text";
import SettingsPagePreferencesContent from "../../components/settings/SettingsPagePreferencesContent";

const SettingsPage = () => {
  const { user } = useAuthStatus();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const [isSideBarActive, setIsSideBarActive] = useState(false);

  const userName = user?.name;
  const userEmail = user?.email;

  const tabs = [SettingsPageTabs.ACCOUNT, SettingsPageTabs.PREFERENCES, SettingsPageTabs.SECURITY];

  const cardAsideClass = isSideBarActive ? "card-aside w-400px" : "card-aside-collapsed";

  return (
    <React.Fragment>
      <Head title={"Settings"} />
      <Content>
        <Row className="w-100 position-relative">
          <Col
            lg="3"
            className={`${cardAsideClass} settings-menu  card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg content-active h-90vh`}
          >
            <Card padding="p-1" className="h-100">
              <Row className="settings-user-avatar-container align-items-center justify-between ">
                <Row className="align-items-center">
                  <UserAvatar size={26} textClass="fs-10px" text={userName?.charAt(0).toUpperCase()} theme="primary" />
                  <div className="user-info">
                    <BlackBodyText100>{userName}</BlackBodyText100>
                    <BlackOverlineText200>{userEmail}</BlackOverlineText200>
                  </div>
                </Row>
                <Toggle
                  className="nk-nav-toggle nk-quick-nav-icon d-lg-none d-lg-none "
                  icon="menu"
                  click={() => setIsSideBarActive(!isSideBarActive)}
                />
              </Row>

              <ul className="nk-menu">
                {tabs.map((t) => (
                  <SettingsPageTabLink
                    tab={t}
                    isActive={pathname.includes(t) || (pathname === "/settings" && t === SettingsPageTabs.ACCOUNT)}
                  />
                ))}
              </ul>
            </Card>
          </Col>
          <Col md="12" lg="9" className="h-90vh pl-2">
            <div className="h-100 w-100 overflow-auto px-2 justify-center">
              {!isSideBarActive && (
                <div>
                  <Toggle
                    className="align-self-end nk-nav-toggle nk-quick-nav-icon  d-lg-none  mt-2 mr-2"
                    icon="menu"
                    click={() => setIsSideBarActive(true)}
                  />
                </div>
              )}
              <Switch>
                <Route exact path={`${path}/security`} component={SettingsPageSecurityContent} />
                <Route exact path={`${path}/preferences`} component={SettingsPagePreferencesContent} />
                <Route exact path={`${path}/invoices`} component={InvoicesContent} />
                <Route exact path={`${path}/invoices/:invoiceId`} component={InvoiceDetailsContent} />
                <Route path={`${path}`} component={SettingsPageAccountContent} />
              </Switch>
            </div>
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default SettingsPage;
