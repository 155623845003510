import Box from "@mui/material/Box";
import * as React from "react";
import { DriverDetailsPageTabKey } from "types/driver.types";
import { StyledTab, StyledTabs } from "../../tab/StyledTab";


type DriverDetailsPageTabProps = {
  onTabChange: (key: DriverDetailsPageTabKey) => void;
  activeTab: DriverDetailsPageTabKey;
};

export default function DriverDetailsPageTab({ onTabChange, activeTab }: DriverDetailsPageTabProps) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue as DriverDetailsPageTabKey);
  };

  return (
    <Box>
      <StyledTabs value={activeTab} onChange={handleChange}>
        <StyledTab label="About" />
        <StyledTab label="Charging sessions" />
        <StyledTab label="Reimbursement" />
        <></>

      </StyledTabs>
    </Box>
  );
}
